// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headings-class{
font-size: 25px !important;
margin-bottom: -30px !important;
margin-left: 5px !important;

}
.sub-para{
    font-size: 18px !important;
    margin-bottom: -5px !important;
    margin-top: 25px !important;
    padding-left: 5px;
}
.headings-class2{
    font-size: 20px;
    margin-bottom: -30px !important;
margin-left: 5px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/mother-sixpillars-dashboard/mother-sixpillars-dashboard.component.css"],"names":[],"mappings":"AAAA;AACA,0BAA0B;AAC1B,+BAA+B;AAC/B,2BAA2B;;AAE3B;AACA;IACI,0BAA0B;IAC1B,8BAA8B;IAC9B,2BAA2B;IAC3B,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,+BAA+B;AACnC,2BAA2B;AAC3B","sourcesContent":[".headings-class{\r\nfont-size: 25px !important;\r\nmargin-bottom: -30px !important;\r\nmargin-left: 5px !important;\r\n\r\n}\r\n.sub-para{\r\n    font-size: 18px !important;\r\n    margin-bottom: -5px !important;\r\n    margin-top: 25px !important;\r\n    padding-left: 5px;\r\n}\r\n.headings-class2{\r\n    font-size: 20px;\r\n    margin-bottom: -30px !important;\r\nmargin-left: 5px !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
