import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { TreeTableComponent } from '../tree-table/tree-table.component';
import { TreeTableDataService } from '../Services/tree-data.service';
import { TreeNode } from 'primeng/api';
import { ContentObserver } from '@angular/cdk/observers';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { FilterComponent } from "../filter/filter.component";
import { TreeTablePageComponent } from "../tree-table-page/tree-table-page.component";
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PopupWeightageFormComponent } from '../popup-weightage-form/popup-weightage-form.component';
import { AuthenticationService } from '../Services/authentication.service'
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-team-tracker',
  standalone: true,
  imports: [TreeTableComponent, MatTableModule, MatPaginatorModule, MatSortModule, FilterComponent, TreeTablePageComponent, MatPaginator, MatFormFieldModule, MatInputModule, FormsModule, MatIconModule, MatButtonModule, MatDividerModule, CommonModule, MatMenuModule, MatDialogModule, DecimalPipe],
  templateUrl: './team-tracker.component.html',
  styleUrl: './team-tracker.component.css',
})
export class TeamTrackerComponent implements OnInit, AfterViewInit {
  isFilterVisible: boolean = false; // Variable to control visibility
  teamTrackerData!: TreeNode[];
  teamTrackerCols: any[] = [];
  currentUser: any;
  teamData: any;
  teamToDisplay: any;

  readonly dialog = inject(MatDialog);

  pageSize: number = 5;
  currentPage: number = 0;
  totalRecords: number = 0;
  paginatedData: TreeNode[] = [];
  displayedColumns: string[] = [
     'stateName', 'districtName', 'blockName','name', 'householdCoverage',
     'childCoverage','child0To2Count', 'child2To6Count', 'diarrheaCases', 'diarrheaFollowUpCoverage',
    'pneumoniaCases', 'pneumoniaFollowUpCoverage', 'childVaccination','totalMotherReg','motherPWCount', 'newMomsCount',
    'motherMeetingCount', 'ancFollowUpCount','VHSND','lowAwareness','score'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private _liveAnnouncer = inject(LiveAnnouncer);

  isPopupOpen = false;
  name = '';
  email = '';

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  toggleFilterVisibility(): void {
    this.isFilterVisible = !this.isFilterVisible;
  }
  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(PopupWeightageFormComponent, {
      width: '80vw',
      maxWidth: '80vw',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  openPopup(): void {
    this.isPopupOpen = true;
  }

  closePopup(): void {
    this.isPopupOpen = false;
  }

  submitForm(): void {
    this.closePopup();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.paginateData();
  }

  paginateData() {
    const start = this.currentPage * this.pageSize;
    const end = start + this.pageSize;
    this.paginatedData = this.teamTrackerData.slice(start, end);
  }

  loadData() {
    console.log('Loading team tracker data...');
    const params={}
    this.authenticationService.getTeamTracker(params)
      .pipe(
        catchError(error => {
          console.error('Error fetching team tracker data:', error);
          if (error.status === 401) {
            console.error('Authorization error. Please check if you are logged in and have the correct permissions.');
          }
          return of([]);
        })
      )
      .subscribe((response: any) => {
        console.log('Team tracker data received:', response);
        if (Array.isArray(response.result)) {
          const transformedData = this.transformData(response.result);
          this.dataSource.data = transformedData;
          console.log('Transformed data:', transformedData);
        } else {
          console.error('Unexpected response format:', response);
          this.dataSource.data = [];
        }
      });
  }

  transformData(data: any[]): any[] {
    return data.map((item) => ({
      name: item.name,
      stateName: item.stateName,
      districtName: item.districtName,
      blockName: item.blockName,
      householdCoverage: item.householdCoverage,
      motherPWCount: item.motherPWCount,
      totalMotherReg: item.totalMotherReg,
      newMomsCount: item.newMomsCount,
      childCoverage: item.childCoverage,
      child0To2Count: item.child0To2Count,
      child2To6Count: item.child2To6Count,
      diarrheaCases: item.diarrheaCases,
      diarrheaFollowUpCoverage: item.diarrheaFollowUpCoverage,
      pneumoniaCases: item.pneumoniaCases,
      pneumoniaFollowUpCoverage: item.pneumoniaFollowUpCoverage,
      childVaccination: item.childVaccination,
      motherMeetingCount: item.motherMeetingCount,
      ancFollowUpCount: item.ancFollowUpCount,
      targetData: item.targetData
    }));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  calculatePercentage(value: number, target: number): number {
    return target > 0 ? (value / target) * 100 : 0;
  }
}
