import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CustomLineChartComponent } from '../custom-line-chart/custom-line-chart.component';
import { StackedColumnChartComponent } from '../stacked-column-chart/stacked-column-chart.component';
import { MapChartComponent } from '../map-chart/map-chart.component';
import { PieChartComponent } from '../pie-chart/pie-chart.component';
import { Router } from '@angular/router';
import { PneumoniaSelfCareService } from '../Services/pneumonia-self-care.service';
import { FilterComponent } from '../filter/filter.component';
import { DiarrheaTreeTableComponent } from '../components/diarrhea-tree-table/diarrhea-tree-table.component';
import { PneumoniaCountTreeTableComponent } from '../components/pneumonia-count-tree-table/pneumonia-count-tree-table.component';
import { OverviewDashboardService } from '../Services/overview-dashboard.service';
import { StepChartComponent } from '../step-chart/step-chart.component';
import { filter } from 'rxjs';
import { FunnelVerticleChartComponent } from '../funnel-verticle-chart/funnel-verticle-chart.component';
//dummy data for Diarrhoea Awareness 25 sept start 
interface DiarrhoeaAwareness {
  title: string;
  total: number;
  boys: number;
  girls: number;
}
//dummy data for Diarrhoea Awareness 25 sept end
export interface otherIndicator {
  year: string;
  la: number;
  cd: number;
  colorla?: string;
  colorcd?: string;
}
interface ChartDataPoint {
  name: string;
  value: number;
  color?: string;
}
interface StackedDataPoint {
  year: string;
  la: number;
  cd: number;
  colorla?: string;
  colorcd?: string;
}

interface TileTopStat {
  title: string;
  target: number;
  covered: number;
}

interface TileCount {
  title: string;
  total: number;
  male: number;
  female: number;
}

interface BeneficiaryData {
  firstName: string;
  age: number;
  mobile: string;
  beneficiaryStatus: string;
  fatherName: string;
  husbandName: string;
}

@Component({
  selector: 'app-pneumonia',
  standalone: true,
  imports: [
    CommonModule,
    CustomLineChartComponent,
    PieChartComponent,
    StackedColumnChartComponent,
    MapChartComponent,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    FilterComponent,
    DiarrheaTreeTableComponent,
    PneumoniaCountTreeTableComponent,
    StepChartComponent,
    FunnelVerticleChartComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './pneumonia.component.html',
  styleUrls: ['./pneumonia.component.css'],
})
export class PneumoniaComponent implements OnInit, AfterViewInit {
   // Input to control visibility of tables
   @Input() showAndHideTablesOfPneumonia: boolean = false;
   @Input() showAndHideTablesOfPneumoniaheading:boolean=false;
  isFilterVisible: boolean = false; // Variable to control visibility
  //25 sept start
  otherIndicatorsData:otherIndicator[] = [];
  otherIndicatorsData2 = [
    { name: "Vaccination", value:96.3 },
    { name: "Early Breastfeeding", value: 65.5 },
    { name: "Exclusive Breastfeeding", value: 97.1 },
    { name: "Safe sanitation/ open defecation", value: 55.3 },
    // { name: "Hand Hygiene > 2", value: 77.3 },
    { name: "Safe and Clean Water", value: 15 },
    { name: "ORS", value: 76.51 },
    { name: "Zinc", value: 32.73 },
    // { name: "Soap Available", value: 78.19 },
    { name: "Clean Fuel", value: 51.08 },
  ];
  
  //25 sept end
  currentUser = localStorage.getItem('currentUser');
  currentUserJson1 = JSON.parse(String(this.currentUser));
  tileCounts: TileCount[] = [];
  colorla: string = '#f99db3';
  colorcd: string = '#8a99e6';
  mapData: any[] = [];
  chartData: any[] = [];  // for other indicators store
  treeColumns = [  
    {
      field: 'name',
      header: 'Name',
    },
    // {
    //   field: 'totalChildrenCovered',
    //   Header: 'Children Covered',
    // },  
    {
      field: 'totalPneumoniaCases',
      header: 'Pneumonia Cases ',
    },
    {
      field: 'totalTreatedAtHome',
      header: 'Treated At Home',
    },
    {
      field: 'totalTreatedAtFacility',
      header: 'Treated At Facility',
    },
    {
      field: 'totalAdmittedToGeneral',
      header: 'Admitted To General',
    },
    {
      field: 'totalAdmittedToNICU',
      header: 'Admitted To NICU ',
    },
    {
      field: 'totalRepeatPneumonia',
      header: 'Repeated Case',
    },
    {
      field: 'totalChildrenCovered',
      Header: 'Children Covered',
    },
    {
      field: 'totalDeathsLT0_2',
      header: 'Child 0-2',
    },
    {
      field: 'totalDeathsLT2_6',
      header: 'Child 2-6',
    },
  ];

  funnelChartDataPneumonia = [
    { value: 3938, category: "Child (0-6)" },
    { value: 291, category: "Pneumonia" },
    { value: 245, category: "Home" },
    { value: 46, category: "Facility" },
    { value: 0, category: "Mortality" }
  ];
  treeTablePneumonia: any = [];
  tileTopStats: {
    tileTopStats: TileTopStat[];
    tileCounts: TileCount[];
  } = {
    tileTopStats: [],
    tileCounts: [], 
  };
  chartData1: ChartDataPoint[] = [];
  chartData2: ChartDataPoint[] = [];
  stackedChartData: StackedDataPoint[] = [];
  displayedColumns2: string[] = [
    'sn',
    'blockName',
    'total',
    'treatedAtHome',
    'treatedAtFacility',
  ];
  dataSource2: MatTableDataSource<BeneficiaryData>;
  exampleData: any;
  exampleDataFor1: any;
  exampleDataFor2: any;
  DataForstack: any;
  stateId = [];
  districtId = [];
  blockId = [];
  villageId = [];
  params = {};
  treePneumoniaDataBifurcation: any;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private pueunmiaService: PneumoniaSelfCareService,
    private overviewDashboardService: OverviewDashboardService
  ) {
    this.dataSource2 = new MatTableDataSource<BeneficiaryData>([]);
    let currentUserJson;
    if (this.currentUser) {
      currentUserJson = JSON.parse(this.currentUser);
    }

    let filter = {};
    if (currentUserJson.user.designation == 'SM') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        type: 'default',
      };
    } else if (currentUserJson.user.designation == 'DL') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        district_Id: [currentUserJson.user.districtId],
        type: 'default',
      };
    }else if (currentUserJson.user.designation == 'BO') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        district_Id: [currentUserJson.user.districtId],
        block_Id: [currentUserJson.user.blockId],
        type: 'default',
      }
    }

  

   // this.generateChartData(filter);
  }

  treeColumnsPneumonia = [
    {
      field: 'name',
      header: 'Name',
    },
    {
      field: 'pneumonia',
      header: 'Pneumonia',
    },
    {
      field: 'severe_pneumonia',
      header: 'Severe Pneumonia',
    },
    {
      field: 'no_pneumonia',
      header: 'No Pneumonia',
    },
  ];


  ngOnInit() {
    this.lowAwarenessVsCouncil();

    this.getDataFromLocalStorage();

    let currentUserJson;
    if (this.currentUser) {
      currentUserJson = JSON.parse(this.currentUser);
    }

    let filter = {};
    if (currentUserJson.user.designation == 'SM') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        type: 'default',
      };
    } else if (currentUserJson.user.designation == 'DL') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        district_Id: [currentUserJson.user.districtId],
        type: 'default',
      };
    } else if (currentUserJson.user.designation == 'BO') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        district_Id: [currentUserJson.user.districtId],
        block_Id: [currentUserJson.user.blockId],
        type: 'default',
      };
    }else if (currentUserJson.user.designation == 'Admin'){
      console.log("admin dec exe")
       filter = {
         designation: currentUserJson.user.designation,
         state_Id: [currentUserJson.user.stateId],
         district_Id: [currentUserJson.user.districtId],
        // block_Id: [currentUserJson.user.blockId],
         type: 'default',
       };
       console.log("admin filter", filter)
       this.getPneumoniaBlockData(filter);
    }
      this.pueunmiaService
        .getChildPneumoniaCountFun(filter)
        .subscribe((data: any) => {
          
          this.tileTopStats = data.tileTopStats;
          this.cdr.detectChanges();
        });

    //this.generateChartData(filter);
    this.getPneumoniaBlockData(filter);
    this.pueunmiaService
      .getChildPneumoniaTreatedStatus(filter)
      .subscribe((data: any) => {
        this.exampleData = data;
        this.dataSource2.data = this.exampleData;
        this.cdr.detectChanges();
        this.generateStackChart(filter);
      });
    this.stackedChartData = [
      {
        year: 'pneumoniaPrevention',
        la: 65,
        cd: 65,
        colorla: '#7ddebe',
        colorcd: '#4a90e2',
      },
      {
        year: 'possibleSymptoms',
        la: 75,
        cd: 55,
        colorla: '#7ddebe',
        colorcd: '#4a90e2',
      },
    ];

    // this.pueunmiaService.getApprovedPneumoniaCases(filter).subscribe((Geodata: any) => {

    // this.cdr.detectChanges();
    // });


      // other indicators
      this.chartData = [
        { "country": "Vaccination", "value": 443 },
        { "country": "Early B/F", "value": 414 },
        { "country": "Exclusive B/F", "value": 385},
        { "country": "Clean  fuel", "value": 280 },
        { "country": "Hand Hygiene", "value": 435 },
        { "country": "Safe sanitation/ OD", "value": 353  },
        { "country": "Safe & Clean Water", "value": 441 },
        { "country": "C. Feces disposal", "value": 287 },
      ];
      console.log("this.initializeStepChartData()", this.chartData);
      this.getDiarrheaOtherIndicator()
  }
   // Method to toggle the visibility of the filter component
   toggleFilterVisibility(): void {
    this.isFilterVisible = !this.isFilterVisible;
  }
  getDataFromLocalStorage() {
    let allUser = localStorage.getItem('currentUser');

    if (allUser) {
      let currentUser: any = JSON.parse(allUser);

      if (currentUser.user.designation === 'SM') {
        this.stateId = currentUser.user['stateId'];
      } else if (currentUser.user.designation === 'DL') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
      } else if (currentUser.user.designation === 'BO') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        this.blockId = currentUser.user['blockId'];
      } else if (currentUser.user.designation === 'CHW') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        this.blockId = currentUser.user['blockId'];
        this.villageId = currentUser.user['villageId'];
      }
    }
    // filterValues['type'] = "filter"
    const currentUserString = JSON.stringify(this.currentUser);
    const currentUser = JSON.parse(currentUserString);
    this.params = {
      state_Id: Array.isArray(this.stateId) ? this.stateId : [this.stateId],
      district_Id: Array.isArray(this.districtId)
        ? this.districtId
        : [this.districtId],
      block_Id: Array.isArray(this.blockId) ? this.blockId : [this.blockId],
      village_Id: Array.isArray(this.villageId)
        ? this.villageId
        : [this.villageId],
      userLevel: currentUser?.user?.designation,
    };

    this.getPneumoniaHierarchyData(this.params);
  }

  ngAfterViewInit(): void {
    let currentUserJson;
    if (this.currentUser) {
      currentUserJson = JSON.parse(this.currentUser);
      console.log(currentUserJson.user.designation);
    }

    let filter = {};
    if (currentUserJson.user.designation == 'SM') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        type: 'default',
      };
    } else if (currentUserJson.user.designation == 'DL') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        district_Id: [currentUserJson.user.districtId],
        type: 'default',
      };
    } else if (currentUserJson.user.designation == 'BO') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: currentUserJson.user.stateId,
        district_Id: [currentUserJson.user.districtId],
        block_Id: [currentUserJson.user.blockId],
        type: 'default',
      };
    }

    this.getPneumoniaBlockData(filter);

    this.pueunmiaService
      .getChildPneumoniaCountFun(filter)
      .subscribe((data: any) => {
        this.tileTopStats = data;

        this.cdr.detectChanges();
      });
    this.generateChartData(filter);
    this.pueunmiaService
      .getChildPneumoniaTreatedStatus(filter)
      .subscribe((data: any) => {
        this.exampleData = data;
        this.dataSource2.data = this.exampleData;
        this.cdr.detectChanges();
        this.generateStackChart(filter);
      });

    this.pueunmiaService
      .getApprovedPneumoniaCases(filter)
      .subscribe((Geodata: any) => {
        this.generateMap(Geodata);

        this.cdr.detectChanges();
      });
    this.getPneumoniaBlockData(filter);

    this.dataSource2.sort = this.sort;
    this.dataSource2.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
    if (this.dataSource2.paginator) {
      this.dataSource2.paginator.firstPage();
    }
  }

  onFilterChange(filterValues: any): void {
    console.log("filter change event emitted-----------------------")
    filterValues['type'] = 'filter';





    this.pueunmiaService.getPneumoniaTreeTableDataApi(filterValues).subscribe(
      (dataValue: any) => {
        console.log('data from the tree table api filterrrrrrrrrrrrrr', dataValue);
        this.treeTablePneumonia = this.transformData(dataValue);
      },
      (error: any) => {
        console.error('Error occurred:', error);
      }
    );


    this.pueunmiaService
      .getChildPneumoniaCountFun(filterValues)
      .subscribe((data: any) => {
        this.tileTopStats = data;

        this.cdr.detectChanges();
      });
    this.pueunmiaService
      .getChildPneumoniaTreatedStatus(filterValues)
      .subscribe((data: any) => {
        this.exampleData = data;
        this.dataSource2.data = this.exampleData;
        this.cdr.detectChanges();
        this.generateChartData(filterValues);
        this.generateStackChart(filterValues);

        this.pueunmiaService
          .getApprovedPneumoniaCases(filterValues)
          .subscribe((Geodata: any) => {
            this.generateMap(Geodata);

            this.cdr.detectChanges();
          });


          this.pueunmiaService
            .getPneumoniaHierarchyData(filterValues)
            .subscribe(
              (dataValue: any) => {
                console.log("fffffff", dataValue.data)
                this.treePneumoniaDataBifurcation = this.transformData(dataValue.data);
                console.log("tree table data new filtered", this.treeTablePneumonia)

                  this.cdr.detectChanges();
          
              },
              (err: any) => {
                console.log('error occured', err);
              }
            );


        this.getPneumoniaBlockData(filterValues);
      });
  }

  export() {
    console.log('Exporting data...');
  }

  async generateStackChart(filter: any) {
    await this.pueunmiaService
      .getChildStackChartDataHome(filter)
      .subscribe((stackdataRes: any) => {
        this.DataForstack = stackdataRes;

        this.cdr.detectChanges();
      });
  }

  generateMap(data: any) {
    let obj = {};
    data.forEach((element: any) => {
      obj = {
        lat: element.geoLocation.latitude,
        lng: element.geoLocation.longitude,
        type: 'svg',
        title: element.block,
      };
      this.mapData.push(obj);
    });
  }

  async generateChartData(filter: any) {
    
    await this.pueunmiaService
      .getChildPneumoniaTreatedDataHome(filter)
      .subscribe((chartdataRes: any) => {
        console.log('exampleDataFor1[0] 11111111', chartdataRes);
        this.exampleDataFor1 = chartdataRes;
        this.chartData1 = [
          {
            name: 'Recovered',
            value: this.exampleDataFor1[0].childRecoveredCount,
            color: '#f6a5b2',
          },
          {
            name: 'Fatalities',
            value: this.exampleDataFor1[0].childFatalitiesCount,
            color: '#8a99e6',
          },
          {
            name: 'U-T',
            value: this.exampleDataFor1[0].childUnderTreatmentCount,
            color: '#77E4C8',
          },
          // {
          //   name: 'F/U',
          //   value: this.exampleDataFor1[0].childFollowUpCount,
          //   color: '#36C2CE',
          // },
          {
            name: 'LAMA',
            value: this.exampleDataFor1[0].childLAMACount,
            color: '#C6F6EF',
          },
          // {
          //   name: 'C/W',
          //   value: this.exampleDataFor1[0].childConditionWorsedCount,
          //   color: '#249465',
          // },
          {
            name: 'Loss To F/U',
            value: this.exampleDataFor1[0].childLossToFollowUpCount,
            color: '#74a0dd',
          },
        ];
        this.cdr.detectChanges();
      });

    await this.pueunmiaService
      .getChildPneumoniaTreatedDataFacility(filter)
      .subscribe((chartdata: any) => {
        this.exampleDataFor2 = chartdata;
        this.cdr.detectChanges();
        this.chartData2 = [
          {
            name: 'Recovered',
            value: this.exampleDataFor2[0].childRecoveredCount,
            color: '#f6a5b2',
          },
          {
            name: 'Fatalities',
            value: this.exampleDataFor2[0].childFatalitiesCount,
            color: '#8a99e6',
          },
          {
            name: 'U-T',
            value: this.exampleDataFor2[0].childUnderTreatmentCount,
            color: '#77E4C8',
          },
          // {
          //   name: 'F/U',
          //   value: this.exampleDataFor2[0].childFollowUpCount,
          //   color: '#36C2CE',
          // },
          {
            name: 'LAMA',
            value: this.exampleDataFor2[0].childLAMACount,
            color: '#C6F6EF',
          },
          // {
          //   name: 'C/W',
          //   value: this.exampleDataFor2[0].childConditionWorsedCount,
          //   color: '#249465',
          // },
          {
            name: 'Loss To F/U',
            value: this.exampleDataFor2[0].childLossToFollowUpCount,
            color: '#74a0dd',
          },
       
        ];
        this.cdr.detectChanges();
      });
  }

  navigateToTreeTable() {
    this.router.navigate(['/tree-table-page']);
  }

  getPneumoniaBlockData(paramsValue: any) {
    const filterDate = {
      isApproval: false,
      designation: 'DL',
      stateId: [],
      districtId: [],
      blockId: [],
    };
    this.pueunmiaService.getPneumoniaTreeTableDataApi(paramsValue).subscribe(
      (dataValue: any) => {
        console.log('data from the tree table api', dataValue);
        this.treeTablePneumonia = this.transformData(dataValue);
      },
      (error: any) => {
        console.error('Error occurred:', error);
      }
    );
  }

  transformData(input: any) {
    const transform = (node: any, key: any) => {
      // Destructure the node, omitting 'parent'
      const { parent, ...restNode } = node;

      const newNode = {
        key: key,
        data: {
          ...restNode, // Copy everything except 'parent'
        },
        children: [],
      };
      if (node.districts) {
        newNode.children = node.districts.map((district: any, index: any) => {
          const districtKey = `${key}-${index}`;
          const transformedDistrict = transform(district, districtKey);
          if (district.blocks) {
            transformedDistrict.children = district.blocks.map(
              (block: any, blockIndex: any) => {
                const blockKey = `${districtKey}-${blockIndex}`;
                return transform(block, blockKey);
              }
            );
          }
          return transformedDistrict;
        });
      } else if (node.blocks) {
        newNode.children = node.blocks.map((district: any, index: any) => {
          const districtKey = `${key}-${index}`;
          const transformedBlocks = transform(district, districtKey);
          return transformedBlocks;
        });
      } else if (node.chwlist || node.chwList) {// in some spelling it is 'L'
        newNode.children =(node.chwlist || node.chwList)?.map((district: any, index: any) => {
            const districtKey = `${key}-${index}`;
            const transformedBlocks = transform(district, districtKey);
            return transformedBlocks;
          }) || []; 
      }

      return newNode;
    };

    return input.map((item: any, index: any) =>
      transform(item, index.toString())
    );
  }

  lowAwarenessVsCouncil() {
    
    this.pueunmiaService.lowAwarenessVsCouncil().subscribe(
      (res: any) => {
        console.log('result of lowawareness', res);
        this.stackedChartData = res.data;
      },
      (err: any) => {
        console.log('Error occured', err);
      }
    );
  }

  getPneumoniaHierarchyData(params: any) {
    // alert("before service")
    const currentuserString = JSON.stringify(this.currentUser);
    const user = JSON.parse(currentuserString);
    const currentUser = JSON.parse(user);

    this.pueunmiaService.getPneumoniaHierarchyData(params).subscribe(
      (res: any) => {
        if (currentUser.user.designation == 'SM') {
          this.treePneumoniaDataBifurcation = this.transformData(res.data[0].districts);
        } else if (currentUser.user.designation == 'DL') {
          this.treePneumoniaDataBifurcation = this.transformData(
            res.data[0].districts[0].blocks
          );
        } else if (currentUser.user.designation == 'BO') {
          this.treePneumoniaDataBifurcation = this.transformData(
            res.data[0].districts[0].blocks[0].chwlist
          );
        } else {
          this.treePneumoniaDataBifurcation = this.transformData(res.data);
        }
      },
      (err: any) => {
        console.log('Error occured');
      }
    );
  }
  getDiarrheaOtherIndicator(params: any = this.params): void {
    this.overviewDashboardService.getOtherIndicatorData().subscribe(
      (res: any) => {
        console.log("Received data:", res);
      //  const data = this.transformOtherIndicatorChartData(res);
      //  this.otherIndicatorsData =data
      },
      (err: any) => {
        console.error('Error occurred in getDiarrheaOtherIndicator:', err);
        // this.error = 'Failed to load other indicator data';
      }
    );
  }
  
  transformOtherIndicatorChartData(data: any): any[] {
    const categories = [
      { key: 'vaccinationStats', name: 'Vaccination', percentageKey: 'percentageVaccinatedUnder12Months' },
      { key: 'breastfeedingStats', name: 'Early Breastfeeding', percentageKey: 'percentageBreastfeedingIn1HrUnder6Months' },
      { key: 'breastfeedingContinueStats', name: 'Exclusive Breastfeeding', percentageKey: 'percentageBreastfeedingContinueUnder6Months' },
      { key: 'defectedInOpenStats', name: 'Safe sanitation/ open defecation', percentageKey: 'percentageDefectedInOpen' },
      { key: 'soapAndHandWashStats', name: 'Hand Hygiene > 2', percentageKey: 'percentageSoapAndHandWash' },
      { key: 'waterSourceStats', name: 'Safe and Clean Water', percentageKey: 'percentageValidWaterSource' },
      { key: 'waterSourceStats', name: 'ORS', percentageKey: 'percentageValidWaterSource' },
      { key: 'waterSourceStats', name: 'Zinc', percentageKey: 'percentageValidWaterSource' },
      { key: 'defectedInOpenStats', name: 'Soap Available', percentageKey: 'percentageDefectedInOpen' },
      { key: 'defectedInOpenStats', name: 'Clean Fuel', percentageKey: 'percentageDefectedInOpen' },
    ];
  
    const transformedData = categories.map(category => {
      const percentageString = data[category.key][category.percentageKey];
      const value = parseFloat(percentageString.replace('%', '')); // Convert percentage string to number
      return {
        name: category.name,
        value: isNaN(value) ? 0 : value
      };
    });
  
    // Sort data in descending order
    transformedData.sort((a, b) => b.value - a.value);
  
    return transformedData;
  }
}