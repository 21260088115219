import { Component, OnInit, OnDestroy, ElementRef, Input } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

@Component({
  selector: 'app-funnel-verticle-chart',
  standalone: true,
  templateUrl: './funnel-verticle-chart.component.html',
})
export class FunnelVerticleChartComponent implements OnInit, OnDestroy {
  private root: am5.Root | undefined;

  // Accept data as input for dynamic values
  @Input() chartData: { value: number, category: string }[] = [];

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    // Check if chart data is provided
    if (!this.chartData || this.chartData.length === 0) {
      console.error('Chart data is missing or empty.');
      return;
    }

    // Create root element
    let root = am5.Root.new(this.elRef.nativeElement.querySelector("#chartdiv"));

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chart = root.container.children.push(
      am5percent.SlicedChart.new(root, {
        layout: root.verticalLayout
      })
    );

    // Create series
    let series = chart.series.push(am5percent.FunnelSeries.new(root, {
      alignLabels: false,
      orientation: "vertical",
      valueField: "value",
      categoryField: "category"
    }));

    // Customize labels to remove percentage symbol and only show raw values
    series.labels.template.setAll({
      text: "{category}: {value}"  // Only show category and value, no percentage
    });

    // Customize tooltips to remove percentage symbol
    series.slices.template.setAll({
      tooltipText: "{category}: {value}"  // Only show category and value in tooltip
    });

    // Set dynamic data
    series.data.setAll(this.chartData);

    // Add legend
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50,
      marginTop: 15,
      marginBottom: 15
    }));
    legend.data.setAll(series.dataItems);

    // Play animations
    series.appear();
    chart.appear(1000, 100);

    this.root = root;
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
}
