// layout.component.ts
import { Component } from '@angular/core';
import { NavigationEnd, Route, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { filter } from 'rxjs';
import { ContentObserver } from '@angular/cdk/observers';
// import { FooterComponent } from './footer/footer.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent {
  constructor(private router: Router) {
    console.log('LayoutComponent constructor called');
  }
  ngOnInit(): void {
    console.log('LayoutComponent ngOnInit called');
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        console.log('Navigation ended:', event.urlAfterRedirects);
        localStorage.setItem('currentRoute', event.urlAfterRedirects);
      });
    if (this.router.url === '/') {
      const currentRoute = localStorage.getItem('currentRoute');

      if (currentRoute) {
        console.log('Navigating to stored route:', currentRoute);
        this.router.navigateByUrl(currentRoute);
      } else {
        console.log('Navigating to main-dashboard');
        this.router.navigateByUrl('main-dashboard');
      }
    }
  }
}
