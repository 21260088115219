import { Component, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { isPlatformBrowser } from '@angular/common';

interface ChartDataPoint {
  name: string;
  value: number;
  color?: string;
}

@Component({
  selector: 'app-pie-chart',
  standalone: true,
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() chartId!: string;
  @Input() data: ChartDataPoint[] = [];
  legendData: { name: string; percentage: number; color?: string }[] = [];


  private chart?: am5percent.PieChart;
  private root?: am5.Root;
  private series?: am5percent.PieSeries;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.createChart();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isPlatformBrowser(this.platformId) && changes['data'] && !changes['data'].firstChange) {
      this.updateChartData();
    }
  }


  private createChart(): void {
    this.root = am5.Root.new(this.chartId);
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    this.root._logo?.dispose();
  
    // Create the PieChart
    this.chart = this.root.container.children.push(
      am5percent.PieChart.new(this.root, {
        layout: this.root.verticalLayout,
      })
    );
  
    this.series = this.chart.series.push(
      am5percent.PieSeries.new(this.root, {
        valueField: "value",
        categoryField: "name",
        fillField: "color"
      })
    );

    // Filter out the "Total" category from the chart data
    // const chartData = this.data.filter(item => item.name !== 'Total');
    this.series.data.setAll(this.data);

    this.series.labels.template.setAll({
      text: "{name}: {value.percent.formatNumber('#.0')}%",
      radius: 8,
      fontSize: 12,
      fill: am5.color(0xffffff),
      textType: "adjusted"

    });

    // Configure ticks to act as lines connecting labels to slices
    this.series.ticks.template.setAll({
      forceHidden: false,  // Show ticks (the lines)
      strokeOpacity: 1,
      strokeWidth: 1,
      stroke: am5.color(0xffffff)
    });

    this.series.appear(1000, 100);

    // Update legend after setting data
    this.updateLegendData(this.series.dataItems);

    let verticalLegend = this.chart.children.push(am5.Legend.new(this.root, {
      centerY: am5.percent(100),
      y: am5.percent(100),
      layout: this.root.gridLayout,
      marginTop: 20
    }));

    verticalLegend.labels.template.setAll({
      fontSize: 13,
      fontWeight: "700"
    });
  
    this.series.slices.template.setAll({
      strokeWidth: 1,
      stroke: am5.color(0xffffff)
    });
    verticalLegend.data.setAll(this.series.dataItems);

  }
  
  private updateChartData(): void {
    if (this.series) {
      this.series.data.setAll(this.data);
    }
  }

  // Update legend data with percentages calculated based on the total diarrhoea cases
  private updateLegendData(dataItems: any): void {
    const totalItem = this.data.find(item => item.name === 'Total');
    const totalValue = totalItem ? totalItem.value : 0; // Use 0 if no 'Total' found
    
    this.legendData = dataItems
      .filter((item: any) => item.get('name') !== 'Total') // Filter out 'Total'
      .map((item: any) => ({
        name: item.get('name'),
        percentage: totalValue > 0 ? (item.get('value') / totalValue * 100) : 0,
        color: item.get('color')
      }));
  }
  
  
  ngOnDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  }
}
