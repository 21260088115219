// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-tracker-global {
    font-weight: 400;
    color: #393738 !important;
    font-size: 1.05rem;
}
.custom-table-css{
    border: 1px solid black !important;
}
.table-header-font{
    font-size: 1.05rem;
    font-weight: 400;
    color: #ffffff !important;
    text-align: center !important;
    background-color: #e787b7 !important;
}
.table-body-font{
    font-size: .9rem;
    font-weight: 400;
    color: #393738 !important;
    text-align: center !important;
}
.custom-column-width {
    width: 170px; /* Set your desired width */
    min-width: 170px;
    max-width: 300px;
  }
.mat-sort-header-content{
    margin-left: 33px;
}`, "",{"version":3,"sources":["webpack://./src/app/team-tracker/team-tracker.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,kCAAkC;AACtC;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;IACzB,6BAA6B;IAC7B,oCAAoC;AACxC;AACA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,yBAAyB;IACzB,6BAA6B;AACjC;AACA;IACI,YAAY,EAAE,2BAA2B;IACzC,gBAAgB;IAChB,gBAAgB;EAClB;AACF;IACI,iBAAiB;AACrB","sourcesContent":[".team-tracker-global {\r\n    font-weight: 400;\r\n    color: #393738 !important;\r\n    font-size: 1.05rem;\r\n}\r\n.custom-table-css{\r\n    border: 1px solid black !important;\r\n}\r\n.table-header-font{\r\n    font-size: 1.05rem;\r\n    font-weight: 400;\r\n    color: #ffffff !important;\r\n    text-align: center !important;\r\n    background-color: #e787b7 !important;\r\n}\r\n.table-body-font{\r\n    font-size: .9rem;\r\n    font-weight: 400;\r\n    color: #393738 !important;\r\n    text-align: center !important;\r\n}\r\n.custom-column-width {\r\n    width: 170px; /* Set your desired width */\r\n    min-width: 170px;\r\n    max-width: 300px;\r\n  }\r\n.mat-sort-header-content{\r\n    margin-left: 33px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
