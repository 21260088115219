// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  :host {
    display: block;
  }
  
  .step-chart-container {
    width: 100%;
    height: 400px; /* Adjust the height as needed */
    margin-bottom: 20px; /* Space between the charts */
  }
  .pneumonia-global-font-color{
    color: #393738 !important;
    font-weight: 400;
  }
  .cover-custom-class{
    font-size: 1rem;
    font-weight:bold;
    color: #36b845;
  }
.pe-fonts-style{
  font-size: 1.1rem !important;
  font-weight: 400px !important;
  color: #393738 !important;
  }
  .text-font{
    font-size: 1rem !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/pneumonia/pneumonia.component.css"],"names":[],"mappings":"EAAE;IACE,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,aAAa,EAAE,gCAAgC;IAC/C,mBAAmB,EAAE,6BAA6B;EACpD;EACA;IACE,yBAAyB;IACzB,gBAAgB;EAClB;EACA;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;EAChB;AACF;EACE,4BAA4B;EAC5B,6BAA6B;EAC7B,yBAAyB;EACzB;EACA;IACE,0BAA0B;EAC5B","sourcesContent":["  :host {\r\n    display: block;\r\n  }\r\n  \r\n  .step-chart-container {\r\n    width: 100%;\r\n    height: 400px; /* Adjust the height as needed */\r\n    margin-bottom: 20px; /* Space between the charts */\r\n  }\r\n  .pneumonia-global-font-color{\r\n    color: #393738 !important;\r\n    font-weight: 400;\r\n  }\r\n  .cover-custom-class{\r\n    font-size: 1rem;\r\n    font-weight:bold;\r\n    color: #36b845;\r\n  }\r\n.pe-fonts-style{\r\n  font-size: 1.1rem !important;\r\n  font-weight: 400px !important;\r\n  color: #393738 !important;\r\n  }\r\n  .text-font{\r\n    font-size: 1rem !important;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
