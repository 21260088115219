import { Component, OnInit, OnDestroy } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

@Component({
  selector: 'app-awareness-changes',
  standalone: true,
  imports: [],
  templateUrl: './awareness-changes.component.html',
  styleUrls: ['./awareness-changes.component.css']
})
export class AwarenessChangesComponent implements OnInit, OnDestroy {
  private root1!: am5.Root;
  private root2!: am5.Root;
  private root3!: am5.Root;
  private root4!: am5.Root;

  ngOnInit() {
    // First set of dummy data
    const data1 = [
      { name: "Menstrual Hygiene", value: 1.87 },
      { name: "UTI/RTI", value: 40.43 },
      { name: "Birth Spacing", value: 65.59 },
      { name: "Food Diversity", value: 98.04 },
      { name: "Mental Health Helpline", value: 8.89 },
    ];  
  
    // Second set of dummy data
    const data2 = [
      { name: "ANC Compliance", value: 93.06 },
      { name: "Early Registration", value: 89.24 },
      { name: "ANC Importance", value: 78.47 },
    ];
  
    // Third set of dummy data
    const data3 = [
      { name: "Diarrhoea Symptoms", value: 78.7 },
      { name: "Pneumonia Symptoms", value: 37 },
      { name: "Critical H/W W/S", value: 4 },
      { name: "ORS", value: 34.6 },
      { name: "ZINC", value: 34.6 },
    ];
  
    // Fourth set of dummy data
    const data4 = [
      { name: "Soap Available", value: 75 },
      { name: "Critical H/W W/S", value: 4 },
      { name: "Toilet Usage", value: 36.3 },
      { name: "Clean Fuel", value: 48},
      { name: "Modern Birth Spacing", value: 65.59 },
    ];
  
    // Define colors to be used for all charts
    const colors1 = ["#018749", "#E4D00A", "#5F9EA0", "#FF6347","#E8785C"];
    const colors2 = ["#C7513E", "#BBAF0C",  "#F19C7B"];
    const colors3 = ["#018749", "#E4D00A", "#5F9EA0", "#FF6347","#E8785C"];
    const colors4 = ["#C7513E", "#BBAF0C",  "#018749","#E4D00A","#E8785C"];
  
    // Create two vertical charts with data1 and data2
    this.createVerticalChart('chartdiv11', data1, colors1);
    this.createVerticalChart('chartdiv22', data2, colors2);
  
    // Create two horizontal charts with data3 and data4
    this.createHorizontalChart('chartdiv33', data3, colors3);
    this.createHorizontalChart('chartdiv44', data4, colors4);
  }
  

  ngOnDestroy() {
    if (this.root1) this.root1.dispose();
    if (this.root2) this.root2.dispose();
    if (this.root3) this.root3.dispose();
    if (this.root4) this.root4.dispose();
  }

  createVerticalChart(chartId: string, data: any[], colors: string[]) {
    let root = am5.Root.new(chartId);
    root.setThemes([am5themes_Animated.new(root)]);

    // Sort the data in descending order
    data.sort((a, b) => b.value - a.value);

    const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomY",
            pinchZoomX: true,
        })
    );

    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineY.set('visible', true);
    cursor.lineX.set('visible', false);

    const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: "name",
            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 5,
            }),
        })
    );

    // Set rotation and font size for x-axis labels correctly
    xAxis.get("renderer").labels.template.setAll({
        rotation: -90,  // Rotate labels by -90 degrees
        fontSize: 15,   // Reduce font size
    });

    const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
            min: 0,
            max: 100,
            numberFormat: "#'%'"
        })
    );

    const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            categoryXField: "name",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}%",
            }),
        })
    );

    series.columns.template.setAll({
        cornerRadiusTL: 5,
        cornerRadiusTR: 5,
        strokeOpacity: 0,
        width: am5.percent(38),
    });

    series.columns.template.adapters.add("fill", (fill, target) => {
        return am5.color(colors[series.columns.indexOf(target) % colors.length]);
    });

    series.columns.template.adapters.add("stroke", (stroke, target) => {
        return am5.color(colors[series.columns.indexOf(target) % colors.length]);
    });

    series.bullets.push(function() {
        return am5.Bullet.new(root, {
            locationY: 1,
            sprite: am5.Label.new(root, {
                text: "{valueY}%",
                fill: am5.color(0x000000),
                populateText: true,
                centerY: 0,
                centerX: am5.p50,
                paddingTop: 1,
                fontSize: 8,
                fontWeight: "bold"  // Add this line to make the text bold
            })
        });
    });
    
    xAxis.data.setAll(data);
    series.data.setAll(data);

    series.appear(1000);
    chart.appear(1000, 100);
}


createHorizontalChart(chartId: string, data: any[], colors: string[]) {
  let root = am5.Root.new(chartId);
  root.setThemes([am5themes_Animated.new(root)]);

  // Sort the data in descending order
  data.sort((a, b) => b.value - a.value);

  const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: "zoomX",
          wheelY: "panY",
          pinchZoomY: true,
      })
  );

  const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
  cursor.lineX.set('visible', true);
  cursor.lineY.set('visible', false);

  const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
          categoryField: "name",
          renderer: am5xy.AxisRendererY.new(root, {
              minGridDistance: 20,
              inversed: true, // Reverse the order for horizontal charts
          }),
      })
  );

  // Decrease font size for y-axis labels
  yAxis.get("renderer").labels.template.setAll({
      fontSize: 13,  // Set your desired font size
  });

  const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {}),
          min: 0,
          max: 100,
          numberFormat: "#'%'"
      })
  );

  const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
          name: "Series",
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: "value",
          categoryYField: "name",
          tooltip: am5.Tooltip.new(root, {
              labelText: "{valueX}%",
          }),
      })
  );

  series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0,
      height: am5.percent(50),
  });

  series.columns.template.adapters.add("fill", (fill, target) => {
      return am5.color(colors[series.columns.indexOf(target) % colors.length]);
  });

  series.columns.template.adapters.add("stroke", (stroke, target) => {
      return am5.color(colors[series.columns.indexOf(target) % colors.length]);
  });

   // Add labels at the end of each bar
   series.bullets.push(function() {
    return am5.Bullet.new(root, {
        locationX: 1,
        sprite: am5.Label.new(root, {
            text: "{valueX}%",
            fill: am5.color(0x000000),
            populateText: true,
            centerY: am5.p50,
            centerX: am5.p100,
            paddingRight:1,
            fontSize: 8,
            fontWeight: "bold"
        })
    });
});

  yAxis.data.setAll(data);
  series.data.setAll(data);

  series.appear(1000);
  chart.appear(1000, 100);
}

}