import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environment/environment.local';

export const apiEndPoint = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private apiUrl = `${apiEndPoint}UserLogins`;
  private loggedIn$: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.loggedIn$ = new BehaviorSubject<boolean>(this.checkLocalStorage());
  }

  private checkLocalStorage(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const currentUser = localStorage.getItem('currentUser');
      return !!currentUser;
    }
    return false;
  }

  login(credentials: { username: string; password: string }): Observable<any> {
    const options = { params: { include: 'user' } };
    return this.http.post(`${this.apiUrl}/login`, credentials, options).pipe(
      tap((response: any) => {
        console.log('Login response:', response);
        if (isPlatformBrowser(this.platformId)) {
          const currentUser = { 
            id: response.id, 
            ...response.user,
            token: response.id // Assuming the token is returned as 'id'
          };
          console.log('Storing currentUser:', currentUser);
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          this.loggedIn$.next(true);
        }
      })
    );
  }

  logout(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('currentUser');
      this.loggedIn$.next(false);
    }
  }

  getChw(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set(
      'filter',
      JSON.stringify(params)
    );
    return this.http.get<any[]>(`${this.apiUrl}`, { params: httpParams })
      .pipe(tap((response: any) => response));
  }

  getTeamTracker(params:any): Observable<any[]> {
    const httpParams = new HttpParams().set(
      'filter',
      JSON.stringify(params)
    );
    return this.http.post<any[]>(`${apiEndPoint}HHListings/teamTracker`, {params:httpParams})
      .pipe(tap((response: any) => {
        return response;
      }));
  }

  isLoggedIn(): boolean {
    const currentUser = localStorage.getItem('currentUser');
    console.log('Checking login status, currentUser:', currentUser);
    return !!currentUser;
  }

  getCurrentUser(): any {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      return JSON.parse(currentUserString);
    }
    return null;
  }

  getTeamPerformanceStateWise(params:any): Observable<any[]> {
    const httpParams = new HttpParams().set(
      'filter',
      JSON.stringify(params)
    );
    return this.http.post<any[]>(`${apiEndPoint}MotherRegs/teamPerformanceStateWise`, {params:httpParams})
      .pipe(tap((response: any) => {
       
        return response;
      }));
  }
  getTeamPerformanceDistrictWise(params:any): Observable<any[]> {
    const httpParams = new HttpParams().set(
      'filter',
      JSON.stringify(params)
    );
    return this.http.post<any[]>(`${apiEndPoint}MotherRegs/teamPerformanceDistrictWise`, {params:httpParams})
      .pipe(tap((response: any) => {
       
        return response;
      }));
  }

}