import { HttpInterceptorFn } from '@angular/common/http';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const currentUserString = localStorage.getItem('currentUser');
  console.log('TokenInterceptor: currentUserString', currentUserString);
  
  if (currentUserString) {
    const currentUser = JSON.parse(currentUserString);
    console.log('TokenInterceptor: currentUser', currentUser);
    
    if (currentUser && currentUser.id) {
      console.log('TokenInterceptor: Adding token to request');
      const modifiedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.id}`
        }
      });
      console.log('TokenInterceptor: Modified request', modifiedReq);
      return next(modifiedReq);
    } else {
      console.log('TokenInterceptor: No id found in currentUser');
    }
  } else {
    console.log('TokenInterceptor: No currentUser found in localStorage');
  }
  
  console.log('TokenInterceptor: Proceeding with original request');
  return next(req);
};