import { bootstrapApplication, platformBrowser } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { enableProdMode } from '@angular/core';
enableProdMode();
platformBrowser().bootstrapModule(AppComponent)
  .catch(err => console.error(err))

bootstrapApplication(AppComponent, appConfig)
  .then(() => console.log('Application bootstrapped successfully'))
  .catch((err) => console.error('Error during bootstrap:', err));