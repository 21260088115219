import { Component } from '@angular/core';

@Component({
  selector: 'app-community-intervention',
  standalone: true,
  imports: [],
  templateUrl: './community-intervention.component.html',
  styleUrl: './community-intervention.component.css'
})
export class CommunityInterventionComponent {

}
