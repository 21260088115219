import { Component, ViewChild, ViewContainerRef, ComponentRef } from '@angular/core';
import { ANCComponent } from '../anc/anc.component';
import { FoodHealthComponent } from '../food-health/food-health.component';
import { VectorBorneDiseasesComponent } from '../vector-borne-diseases/vector-borne-diseases.component';
import { HygieneAndHealthComponent } from '../hygiene-and-health/hygiene-and-health.component';
import { YogaAndMentalHealthComponent } from '../yoga-and-mental-health/yoga-and-mental-health.component';
import { BirthSpacingComponent } from '../birth-spacing/birth-spacing.component';

@Component({
  selector: 'app-mother-sixpillars-dashboard',
  standalone: true,
  imports: [
    ANCComponent,
    FoodHealthComponent,
    VectorBorneDiseasesComponent,
    HygieneAndHealthComponent,
    YogaAndMentalHealthComponent,
    BirthSpacingComponent
  ],
  templateUrl: './mother-sixpillars-dashboard.component.html',
  styleUrls: ['./mother-sixpillars-dashboard.component.css']
})
export class MotherSixpillarsDashboardComponent {

  // ViewChild decorators for each component's container
  @ViewChild('dynamicContainerofAnc', { read: ViewContainerRef, static: true }) ancContainer!: ViewContainerRef;
  @ViewChild('dynamicContainerofFoodAndHealth', { read: ViewContainerRef, static: true }) foodHealthContainer!: ViewContainerRef;
  @ViewChild('dynamicContainerofVectorBorneDiseases', { read: ViewContainerRef, static: true }) vectorBorneDiseasesContainer!: ViewContainerRef;
  @ViewChild('dynamicContainerofHygieneAndHealth', { read: ViewContainerRef, static: true }) hygieneAndHealthContainer!: ViewContainerRef;
  @ViewChild('dynamicContainerofYogaAndMentalHealth', { read: ViewContainerRef, static: true }) yogaAndMentalHealthContainer!: ViewContainerRef;
  @ViewChild('dynamicContainerofBirthSpacing', { read: ViewContainerRef, static: true }) birthSpacingContainer!: ViewContainerRef;

  ngOnInit() {
    this.loadANCComponent();
    this.loadFoodHealthComponent();
    this.loadVectorBorneDiseasesComponent();
    this.loadHygieneAndHealthComponent();
    this.loadYogaAndMentalHealthComponent();
    this.loadBirthSpacingComponent();
  }

  loadANCComponent(): void {
    this.ancContainer.clear();
    const componentRef: ComponentRef<ANCComponent> = this.ancContainer.createComponent(ANCComponent);
    // Set properties if needed
    componentRef.instance.showAndHideTablesOfAnc = false; // Hide tables
  
  }

  loadFoodHealthComponent(): void {
    this.foodHealthContainer.clear();
    const componentRef: ComponentRef<FoodHealthComponent> = this.foodHealthContainer.createComponent(FoodHealthComponent);
  }

  loadVectorBorneDiseasesComponent(): void {
    this.vectorBorneDiseasesContainer.clear();
    const componentRef: ComponentRef<VectorBorneDiseasesComponent> = this.vectorBorneDiseasesContainer.createComponent(VectorBorneDiseasesComponent);
   //hide table in a mother-sixpillar-dashboard component
    componentRef.instance.showAndHideTablesOfVectorBornDiseases = false; // Hide tables
  }

  loadHygieneAndHealthComponent(): void {
    this.hygieneAndHealthContainer.clear();
    const componentRef: ComponentRef<HygieneAndHealthComponent> = this.hygieneAndHealthContainer.createComponent(HygieneAndHealthComponent);
    // Set properties if needed
    componentRef.instance.showAndHideTablesOfHealthAndHygiene = false; 
  }

  loadYogaAndMentalHealthComponent(): void {
    this.yogaAndMentalHealthContainer.clear();
    const componentRef: ComponentRef<YogaAndMentalHealthComponent> = this.yogaAndMentalHealthContainer.createComponent(YogaAndMentalHealthComponent);
    // Set properties if needed
     componentRef.instance.showAndHideTablesOfYogaAndMentalHealth = false;
  }

  loadBirthSpacingComponent(): void {
    this.birthSpacingContainer.clear();
    const componentRef: ComponentRef<BirthSpacingComponent> = this.birthSpacingContainer.createComponent(BirthSpacingComponent);
    // Set properties if needed
     // Set properties if needed
     componentRef.instance.showAndHideTablesOfBirthSpacing = false;
  }
}
