import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  Inject,
  PLATFORM_ID,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

@Component({
  selector: 'app-step-chart',
  standalone: true,
  imports: [],
  templateUrl: './step-chart.component.html',
  styleUrls: ['./step-chart.component.css']
})
@Component({
  selector: 'app-step-chart',
  standalone: true,
  imports: [],
  templateUrl: './step-chart.component.html',
  styleUrls: ['./step-chart.component.css']
})
export class StepChartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() chartId!: string;
  @Input() data!: any[];

  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  private xAxis!: am5xy.CategoryAxis<am5xy.AxisRendererX>;
  private yAxis!: am5xy.ValueAxis<am5xy.AxisRendererY>;
  private series!: am5xy.ColumnSeries;
  private isBrowser: boolean;

  constructor(
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.isBrowser && changes['data'] && !changes['data'].isFirstChange()) {
      // If the data input changes, update the chart
      this.updateChart();
    }
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.createChart();
    }
  }

  ngOnDestroy() {
    if (this.isBrowser && this.root) {
      this.root.dispose();
    }
  }

  private createChart() {
    if (!this.chartId || !this.data) {
      console.error('Chart ID or data is missing');
      return;
    }

    this.root = am5.Root.new(this.chartId);

    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        wheelX: "none",
        wheelY: "none",
        paddingLeft: 0
      })
    );

    const xRenderer = am5xy.AxisRendererX.new(this.root, {
      minGridDistance: 30,
      minorGridEnabled: true
    });
    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: 0,
      paddingRight: 5,
      fontSize:12
    });
    xRenderer.grid.template.set("visible", false);

    this.xAxis = this.chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        maxDeviation: 0,
        categoryField: "name",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {})
      })
    );

    this.yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        maxDeviation: 0,
        min: 0,
        renderer: am5xy.AxisRendererY.new(this.root, {})
      })
    );

    this.series = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: "Series 1",
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: "value",
        categoryXField: "name",
      })
    );

    this.series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0
    });

    this.series.columns.template.adapters.add("fill", (fill, target) => {
      return this.chart.get("colors")?.getIndex(this.series.columns.indexOf(target));
    });

    this.series.columns.template.adapters.add("stroke", (stroke, target) => {
      return this.chart.get("colors")?.getIndex(this.series.columns.indexOf(target));
    });

    this.series.bullets.push(() => {
      return am5.Bullet.new(this.root, {
        locationY: 1,
        sprite: am5.Label.new(this.root, {
          text: "{valueY.formatNumber('#.0')}%",
          fill: this.root.interfaceColors.get("alternativeText"),
          centerY: 0,
          centerX: am5.p50,
          populateText: true
        })
      });
    });

    this.updateChart();
  }

  private updateChart() {
    if (this.data && this.xAxis && this.series) {
      // Sort data in descending order
      this.data.sort((a, b) => b.value - a.value);

      // Update data
      this.xAxis.data.setAll(this.data);
      this.series.data.setAll(this.data);
    }
  }
}
