import { Component, AfterViewInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

@Component({
  selector: 'app-funnel-chart',
  standalone: true,
  templateUrl: './funnel-chart.component.html',
  styleUrls: ['./funnel-chart.component.css']
})
export class FunnelChartComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() chartData: { value: number; category: string }[] = [];
  @Input() containerId: string = '';  // Input for unique container ID
  private root!: am5.Root;

  ngAfterViewInit(): void {
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData'] && this.root) {
      this.updateChartData();
    }
  }

  createChart(): void {
    // Check if container ID is provided
    if (!this.containerId) {
      console.error("Container ID is missing for Funnel Chart.");
      return;
    }

    // Create root element with unique container ID
    this.root = am5.Root.new(this.containerId);

    // Set themes
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    // Create chart
    let chart = this.root.container.children.push(
      am5percent.SlicedChart.new(this.root, {
        layout: this.root.verticalLayout
      })
    );

    // Create series
    let series = chart.series.push(
      am5percent.FunnelSeries.new(this.root, {
        alignLabels: false,
        orientation: "horizontal",
        valueField: "value",
        categoryField: "category",
        bottomRatio: 1
      })
    );

    // Customize the labels to show the numeric value instead of percentages
    series.labels.template.setAll({
      text: "{category}: {value}",  // This will display the category and numeric value
      populateText: true
    });

    // Set data
    series.data.setAll(this.chartData);

    // Play initial series animation
    series.appear();

    // Create legend
    let legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50,
        marginTop: 15,
        marginBottom: 15
      })
    );

    legend.data.setAll(series.dataItems);

    // Customize legend labels to show value
    legend.labels.template.set("text", "{category}: {value}");

    // Animate chart appearance
    chart.appear(1000, 100);
  }

  updateChartData(): void {
    if (this.root) {
      const chart = this.root.container.children.getIndex(0) as am5percent.SlicedChart;
      const series = chart.series.getIndex(0) as am5percent.FunnelSeries;
      if (series) {
        series.data.setAll(this.chartData);
      }
    }
  }

  ngOnDestroy(): void {
    // Cleanup the chart on component destroy
    if (this.root) {
      this.root.dispose();
    }
  }
}
