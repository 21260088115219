import { Component, ComponentRef, OnInit, ViewContainerRef } from '@angular/core';
import { FunnelChartComponent } from '../funnel-chart/funnel-chart.component'; // Import the funnel chart component
import { TeamPerformanceComponent } from '../team-performance/team-performance.component';
import { StackedColumnChartComponent } from '../stacked-column-chart/stacked-column-chart.component';

interface StackedDataPoint {
  year: string;
  la: number;
  cd: number;
  colorla?: string;
  colorcd?: string;
}

@Component({
  selector: 'app-new-dashboard',
  standalone: true,
  imports: [TeamPerformanceComponent, FunnelChartComponent,StackedColumnChartComponent], // Add FunnelChartComponent here
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.css']
})
export class NewDashboardComponent implements OnInit {

  // for child column chart
  stackedChartData: StackedDataPoint[] = [
    {
      year: 'AV-Zinc',
      la: 75,
      cd: 0,
      colorla: '#7ddebe',
      colorcd: '#4a90e2',
    },
    {
      year: 'AV-ORS',
      la: 65,
      cd: 0,
      colorla: '#7ddebe',
      colorcd: '#4a90e2',
    },
    {
      year: 'AMox.',
      la: 55,
      cd: 0,
      colorla: '#7ddebe',
      colorcd: '#4a90e2',
    },
    {
      year: 'Corner O/Z',
      la: 45,
      cd: 0,
      colorla: '#7ddebe',
      colorcd: '#4a90e2',
    },
  ];
  // for mother column chart
  stackedChartData2: StackedDataPoint[] = [
    {
      year: 'ANC > = 4',
      la: 75,
      cd: 0,
      colorla: '#7ddebe',
      colorcd: '#4a90e2',
    },
    {
      year: 'Early Regn.',
      la: 65,
      cd: 0,
      colorla: '#7ddebe',
      colorcd: '#4a90e2',
    },
    {
      year: 'VBD Awareness',
      la: 55,
      cd: 0,
      colorla: '#7ddebe',
      colorcd: '#4a90e2',
    },
    {
      year: 'B/S Awareness',
      la: 45,
      cd: 0,
      colorla: '#7ddebe',
      colorcd: '#4a90e2',
    },
    {
      year: 'HW Soap',
      la: 35,
      cd: 0,
      colorla: '#7ddebe',
      colorcd: '#4a90e2',
    },
  ];


  // Data for Diarrhoea Cases Funnel Chart
  funnelChartData = [
    { value: 3938, category: "Child (0-6)" },
    { value: 70, category: "Diarrhoea" },
    { value: 62, category: "Home" },
    { value: 8, category: "Facility" },
    { value: 0, category: "Mortality" }
  ];

  // Data for Pneumonia Cases Funnel Chart
  funnelChartData2 = [
    { value: 3938, category: "Child (0-6)" },
    { value: 291, category: "Pneumonia" },
    { value: 245, category: "Home" },
    { value: 46, category: "Facility" },
    { value: 0, category: "Mortality" }
  ];

 
  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    this.loadTeamPerformanceComponent();
  }

  loadTeamPerformanceComponent(): void {
    this.viewContainerRef.clear();
    const componentRef: ComponentRef<TeamPerformanceComponent> = this.viewContainerRef.createComponent(TeamPerformanceComponent);
    
    // Hide the tables (set showTables to false)
    componentRef.instance.showTables = false;
  }

  // Function to update funnel chart data dynamically
  updateFunnelChartData(newData: { value: number; category: string }[]) {
    this.funnelChartData = newData;
  }

  updateFunnelChartData2(newData: { value: number; category: string }[]) {
    this.funnelChartData2 = newData;
  }


  
}
