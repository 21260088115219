import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule } from '@angular/forms';
import { StateService } from '../Services/HierarchyService/state-service.service';
import { DistrictService } from '../Services/HierarchyService/district-service.service';
import { BlockService } from '../Services/HierarchyService/block-service.service';
import { VillageService } from '../Services/HierarchyService/village-service.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOption } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AuthenticationService} from '../Services/authentication.service';


interface Approval {
  value: boolean;
  viewValue: string;
}
interface SelectValues {
  value: boolean;
  viewValue: string;
}


@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    MatRadioModule,
    FormsModule,
    MatButtonToggleModule,
    MatSelectModule
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent implements OnInit {
  showDatePickers: boolean = false;
  filterForm: FormGroup;
  @ViewChild('allSelected') private allSelected!: MatOption;
  @ViewChild('alSelected') private alSelected!: MatOption;
  @Output() filterChange = new EventEmitter<any>();

  states: any[] = [];
  districts: any[] = [];
  blocks: any[] = [];
  chws: any[] = [];
  villages: any[] = [];
  currentUser: any;
  fromDate: Date | null = null;
  toDate: Date | null = null;
  showText: string = "Select date";
  isDefaultDateRange = false;
  selectedDateRange: string = 'default';
  byDefaultChecked: boolean = true;
  isApproval:boolean = false;  // 12 sept

  // Date form control
  dateForm = new FormGroup({
    from_date: new FormControl(),
    to_date: new FormControl()
  });

  // 12 sept
  approvalData  = new FormGroup({
    isApproval : new FormControl(),
  })

  approval: Approval[] = [
    { value: true, viewValue: 'Approved' },
    { value: false, viewValue: 'All' },
  ];
  
  selectOptions:SelectValues[] = [
    { value: true, viewValue: 'Diarrhoea' },
    { value: true, viewValue: 'Pneumonia' },
    { value: true, viewValue: 'WHO 7 Point' },
    { value: true, viewValue: 'Mothers' },
    { value: true, viewValue: 'Meetings' }
  ];
  


  //9 september B-shri start
  viewType = '';
  calType: 'year' | 'month' = 'month';
  activeDate = new Date();
  quarterStartDate: Date | null = null;
  quarterStartDate1!: Date;
  quarterStartDate2!: Date;
  quarterStartDate3!: Date;
  quarterStartDate4!: Date;
  quarterEndDate: Date | null = null;
  quarterEndDate1: Date | null = null;
  quarterEndDate2: Date | null = null;
  quarterEndDate3: Date | null = null;
  quarterEndDate4: Date | null = null;
  //9 september B-shri end
  
 //12 sept  -B start
 selectedValue!: string;
  

  //12 sept  -B end 

  constructor(
    private fb: FormBuilder,
    private blockService: BlockService,
    private districtService: DistrictService,
    private stateService: StateService,
    private villageService: VillageService,
    private chwService:AuthenticationService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.filterForm = this.fb.group({
      state_Id: [''],
      district_Id: [''],
      block_Id: [''],
      village_Id: [''],
      chw_Id: [''],
      from_date: [''],
      to_date: [''],
      quarterDate: [''],
      isApproval: [this.isApproval], //by default is false 12 september
    });
    this.dateForm = this.fb.group({
      from_date: [''],
      to_date: ['']
    });
    this.approvalData =  this.fb.group({
      isApproval: ['']
    })

   
  }

  ngOnInit() {
    const userData = localStorage.getItem('currentUser');
    if (userData) {
      this.currentUser = JSON.parse(userData);
      this.initializeFormForUserAccess(this.currentUser);
    }
    this.filterForm.valueChanges.subscribe((values) => {
      this.filterChange.emit(values);
    });
   
  }

  initializeFormForUserAccess(user: any) {
    const { designation, stateId, districtId, blockId, villageId, id,isApprovalId } = user.user;

    if (designation === 'Admin') {
      this.loadStates();
    } else if (designation === 'SM') {
      this.filterForm.patchValue({ state_Id: [stateId] });
      this.loadDistricts({ value: [stateId] });
    } else if (designation === 'DL') {
      this.filterForm.patchValue({
        state_Id: [stateId],
        district_Id: [districtId]
      });
      this.loadBlocks({ value: [districtId] });
    } else if (designation === 'BO') {
      this.filterForm.patchValue({
        state_Id: [stateId],
        district_Id: [districtId],
        block_Id: [blockId],
      });
      this.loadVillages({ value: [blockId] });
      this.loadChws({ value: [blockId] });
    } else if (designation === 'CHW') {
      this.filterForm.patchValue({
        state_Id: [stateId],
        district_Id: [districtId],
        block_Id: [blockId],
        village_Id: villageId,    
        chw: id,    
      });
      const params = { where: { id: { inq: villageId } } };
      const chwparams = { where: { blockId: { inq: id }, designation :'CHW' } };
      this.villageService.getVillages(params).subscribe(data => {
       
        
        this.villages = data;
        this.changeDetector.markForCheck();
      });
      
      this.chwService.getChw(chwparams).subscribe(data => {
        this.chws = data;
        this.changeDetector.markForCheck();
      });
    }
  }

  loadStates() {
    this.stateService.getStates().subscribe((data) => {
      this.states = data;
      this.changeDetector.markForCheck();
    });
  }

  loadDistricts(event: { value: any[] }) {
    const params = { where: { stateId: { inq: event.value } } };
    this.districtService.getDistricts(params).subscribe((data) => {
      this.districts = data;
      this.changeDetector.markForCheck();
    });
  }

  loadBlocks(event: { value: any[] }) {
    const params = { where: { districtId: { inq: event.value } } };
    this.blockService.getBlocks(params).subscribe((data) => {
      this.blocks = data;
      this.changeDetector.markForCheck(); 
    });
  }
  


  loadVillages(event: { value: any[] }) {
    const params = { where: { blockId: { inq: event.value } } };
    this.villageService.getVillages(params).subscribe(data => {
      this.villages = data;
      this.changeDetector.markForCheck();
    });
  }
  // 17 sept - B  start 
 loadChws(event: { value: any[] }) {
    const params = { where: { blockId: { inq: event.value }, designation: 'CHW' } };

    this.chwService.getChw(params).subscribe(data => {
      this.chws = data;
      this.changeDetector.markForCheck();
    });
}

  
// 17 sept - B  end 
toggleAllSelection(type: 'state' | 'district' | 'block' | 'village' | 'chw') {
  const control = this.filterForm.get(`${type}_Id`);
  const allSelected = this.isAllSelected(type);

  if (control) {
    if (allSelected) {
      control.setValue([]); // Deselect all
    } else {
      let allIds: number[] = [];
      if (type === 'state') {
        allIds = this.states.map((s) => s.id);
        control.setValue(allIds); // Select all states
        this.loadDistricts({ value: allIds }); 
      } else if (type === 'district') {
        allIds = this.districts.map((d) => d.id);
        control.setValue(allIds);
        this.loadBlocks({ value: allIds });
      } else if (type === 'block') {
        allIds = this.blocks.map((b) => b.id);
        control.setValue(allIds);
        this.loadVillages({ value: allIds });
        this.loadChws({ value: allIds }); // Load CHWs
      } 
      // else if (type === 'village') {
      //   allIds = this.villages.map((v) => v.id);
      //   control.setValue(allIds); 
      //   this.loadChws({ value: allIds }); 
      // } 
      else if (type === 'chw') {
        allIds = this.chws.map((c) => c.id);
        control.setValue(allIds); // Select all CHWs 
      }
    }
  }
}

isAllSelected(type: 'state' | 'district' | 'block' | 'village' | 'chw'): boolean {
  const controlValue = this.filterForm.get(`${type}_Id`)?.value || [];
  const allIds =
    type === 'state'
      ? this.states.map((s) => s.id)
      : type === 'district'
        ? this.districts.map((d) => d.id)
        : type === 'block'
          ? this.blocks.map((b) => b.id)
          // : type === 'village'
          //   ? this.villages.map((v) => v.id)
            : type === 'chw'
              ? this.chws.map((c) => c.id) : [];  // CHWs 
          

    return allIds.every((id) => controlValue.includes(id));
  }

  onClose(filterValues: any) {
    this.filterChange.emit(filterValues);
  }

  triggerChangeDetection() {
    this.changeDetector.detectChanges();
  }

  onDateRangeChange(event: MatRadioChange) {
    this.selectedDateRange = event.value;
    this.showDatePickers = this.selectedDateRange === 'default' || this.selectedDateRange === 'quarter';
    this.isDefaultDateRange = this.selectedDateRange === 'default';
  }

  // 9 september B-shri code start
  changeView(): void {
    const year = this.activeDate.getFullYear();
    let filterValues: any = {};
    let fromDate: Date;
    let toDate: Date;

    switch (this.viewType) {
      case 'q1':
        this.calType = 'month';
        fromDate = new Date(year, 0, 1);  // January 1st
        toDate = new Date(year, 3, 0);    // March 31st (last day of March)
        // this.quarterStartDate1 = fromDate;  // when you want to display date range uncomment it for from date
        // this.quarterEndDate1 = toDate;  // when you want to display date range uncomment it for to date 
        filterValues = {
          from_date: fromDate,
          to_date: toDate,
          quarter: 'Q1'
        };
        break;

      case 'q2':
        this.calType = 'month';
        fromDate = new Date(year, 3, 1);  // April 1st
        toDate = new Date(year, 6, 0);    // June 30th (last day of June)
        filterValues = {
          from_date: fromDate,
          to_date: toDate,
          quarter: 'Q2'
        };
        break;

      case 'q3':
        this.calType = 'month';
        fromDate = new Date(year, 6, 1);  // July 1st
        toDate = new Date(year, 9, 0);    // September 30th (last day of September)
        filterValues = {
          from_date: fromDate,
          to_date: toDate,
          quarter: 'Q3'
        };
        break;

      case 'q4':
        this.calType = 'month';
        fromDate = new Date(year, 9, 1);  // October 1st
        toDate = new Date(year, 12, 0);   // December 31st (last day of December)
        filterValues = {
          from_date: fromDate,
          to_date: toDate,
          quarter: 'Q4'
        };
        break;

      default:
        this.calType = 'year';
        fromDate = new Date(year, 0, 1);  // January 1st
        toDate = new Date(year, 11, 31);  // December 31st
        filterValues = {
          from_date: fromDate,
          to_date: toDate,
          quarter: 'Full Year'
        };
    }

    this.filterForm.patchValue({
      from_date: fromDate,
      to_date: toDate
    });
   
    this.dateForm.patchValue({
      from_date: fromDate,
      to_date: toDate
    });
    this.approvalData.patchValue({
      isApproval:this.isApproval
      
    });
  }
  // 9 september B-shri code end 

}