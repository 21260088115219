import { Component, OnInit, OnDestroy, ElementRef, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

interface ChartDataPoint {
  name: string;
  value: number;
}

@Component({
  selector: 'app-donut-chart',
  standalone: true,
  imports: [],
  template: '<div [id]="chartId" style="width: 100%; height: 400px;"></div>',
  styleUrl: './donut-chart.component.css'
})

export class DonutChartComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() data: ChartDataPoint[] = [];
  @Input() chartId: string = 'chartdiv';

  private root!: am5.Root;
  private chart!: am5percent.PieChart;
  private series!: am5percent.PieSeries;
  private legend!: am5.Legend;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    // Chart will be initialized in ngAfterViewInit to ensure DOM is ready
  }

  ngAfterViewInit(): void {
    // Ensure DOM is fully rendered before creating the chart
    setTimeout(() => {
      this.createChart();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && !changes['data'].firstChange && this.series) {
      this.updateChartData();
    }
  }

  private createChart(): void {
    const chartElement = this.elementRef.nativeElement.querySelector(`#${this.chartId}`);

    if (!chartElement) {
      console.error(`Element with id "${this.chartId}" not found`);
      return;
    }

    try {
      // Create root element
      this.root = am5.Root.new(chartElement);

      // Set themes
      this.root.setThemes([am5themes_Animated.new(this.root)]);

      // Create chart
      this.chart = this.root.container.children.push(am5percent.PieChart.new(this.root, {
        radius: am5.percent(90),
        innerRadius: am5.percent(50),
        layout: this.root.horizontalLayout
      }));

      // Create series
      this.series = this.chart.series.push(am5percent.PieSeries.new(this.root, {
        name: "Series",
        valueField: "value",
        categoryField: "name"
      }));

      // Configure series
      this.series.labels.template.set("visible", false);
      this.series.ticks.template.set("visible", false);
      this.series.slices.template.set("strokeOpacity", 0);
      this.series.slices.template.set("fillGradient", am5.RadialGradient.new(this.root, {
        stops: [
          { brighten: -0.8 },
          { brighten: -0.8 },
          { brighten: -0.5 },
          { brighten: 0 },
          { brighten: -0.5 }
        ]
      }));

      // Create legend
      this.legend = this.chart.children.push(am5.Legend.new(this.root, {
        centerY: am5.percent(50),
        y: am5.percent(50),
        layout: this.root.verticalLayout
      }));
      
      // Set value labels align to right and wrap long names
      this.legend.valueLabels.template.setAll({ textAlign: "right" });
      this.legend.labels.template.setAll({ 
        maxWidth: 140, 
        width: 140, 
        oversizedBehavior: "wrap" 
      });

      // Initial data
      this.updateChartData();

      // Play initial animation
      this.series.appear(1000, 100);
      
    } catch (error) {
      console.error('Error creating chart:', error);
    }
  }

  private updateChartData(): void {
    if (this.series && this.data) {
      // Set new data
      this.series.data.setAll(this.data);

      // Update the legend data
      if (this.legend) {
        this.legend.data.setAll(this.series.dataItems);
      }
    }
  }

  ngOnDestroy(): void {
    // Dispose of the root when the component is destroyed
    if (this.root) {
      this.root.dispose();
    }
  }
}
