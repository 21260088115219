import { Component, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core';
import { DiarrheaComponent } from '../diarrhea/diarrhea.component';
import { PneumoniaComponent } from '../pneumonia/pneumonia.component';

@Component({
  selector: 'app-child-diarrhoea-pneumonia',
  standalone: true,
  imports: [DiarrheaComponent, PneumoniaComponent],
  templateUrl: './child-diarrhoea-pneumonia.component.html',
  styleUrls: ['./child-diarrhoea-pneumonia.component.css']
})
export class ChildDiarrhoeaPneumoniaComponent {

  @ViewChild('dynamicContainerofDiarrhoea', { read: ViewContainerRef, static: true }) diarrheaContainer!: ViewContainerRef;
  @ViewChild('dynamicContainerofPneumonia', { read: ViewContainerRef, static: true }) pneumoniaContainer!: ViewContainerRef;

  ngOnInit() {
    this.loadDiarrheaComponent();
    this.loadPneumoniaComponent();
  }

  loadDiarrheaComponent(): void {
    this.diarrheaContainer.clear();
    const componentRef: ComponentRef<DiarrheaComponent> = this.diarrheaContainer.createComponent(DiarrheaComponent);
    componentRef.instance.showAndHideTablesOfDiarrhoea = false; // Hide tables
  }

  loadPneumoniaComponent(): void {
    this.pneumoniaContainer.clear();
    const componentRef: ComponentRef<PneumoniaComponent> = this.pneumoniaContainer.createComponent(PneumoniaComponent);
    componentRef.instance.showAndHideTablesOfPneumonia = false; // Hide tables
    componentRef.instance.showAndHideTablesOfPneumoniaheading = true; // Hide tables
  }
}
